<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ labelPage }} Freelance</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-freelance">Freelance</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage }}
                  </li>
                </ol>
              </nav>
            </div>
            <div class="button-edit" v-show="isEditable === false">
              <button class="btn btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div v-if="isEditable">
              <button
                class="btn btn-save btn-resign mr-2"
                type="button"
                data-toggle="dropdown"
                aria-expanded="false"
                @click="onDelete"
                v-if="labelPage == 'Detail'"
                :disabled="isDelete"
              >
                <span class="fas fa-spinner fa-spin" v-if="isDelete"></span>
                Delete
              </button>
              <button class="btn btn-save" :disabled="isSubmit || isDelete">
                <span class="fas fa-spin fa-spinner" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>
          <div class="card info-detail">
            <div class="row mt-3">
              <div class="col-12">
                <div class="title">Detail Informasi</div>
                <div class="sub-title">Informasi detail dari freelance</div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Nama :</label>
                  <input
                    type="text"
                    v-model="formData.nama"
                    class="form-control align-self-center"
                    @keyup="formChange('nama')"
                    :disabled="!isEditable"
                    :class="{ 'is-invalid': formError && formError.nama }"
                    placeholder="Masukkan Nama Karyawan"
                  />
                  <div class="form-error" v-if="formError && formError.nama">
                    {{ formError.nama }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Nomor Induk Kependudukan :</label>
                  <input
                    type="text"
                    v-model="formData.no_ktp"
                    :disabled="!isEditable"
                    class="form-control align-self-center"
                    @keyup="formChange('no_ktp')"
                    :class="{ 'is-invalid': formError && formError.no_ktp }"
                    placeholder="Masukkan Nomor Induk Kependudukan"
                  />
                  <div class="form-error" v-if="formError && formError.no_ktp">
                    {{ formError.no_ktp }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label> Nomor Telepon :</label>
                  <input
                    type="text"
                    v-model="formData.no_hp"
                    class="form-control align-self-center"
                    @keyup="formChange('no_hp')"
                    :class="{ 'is-invalid': formError && formError.no_hp }"
                    placeholder="Masukkan Nomor Telepon"
                    :disabled="!isEditable"
                    v-maska="'0###############'"
                  />
                  <div class="form-error" v-if="formError && formError.no_hp">
                    {{ formError.no_hp }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Email :</label>
                  <input
                    type="email"
                    v-model="formData.email"
                    class="form-control align-self-center"
                    @keyup="formChange('email')"
                    :class="{ 'is-invalid': formError && formError.email }"
                    placeholder="Masukkan Email"
                    :disabled="!isEditable"
                  />
                  <div class="form-error" v-if="formError && formError.email">
                    {{ formError.email }}
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label> Jenis Kelamin :</label>
                  <div class="row">
                    <div class="col-12 mt-1" style="margin-bottom: 10px">
                      <label
                        v-for="(value, index) in OptionsGender"
                        :key="index"
                        :for="`gender${index}`"
                        class="mr-2"
                      >
                        <input
                          type="radio"
                          :id="`gender${index}`"
                          :value="value"
                          v-model="formData.jenis_kelamin"
                          :disabled="!isEditable"
                          @change="formChange('jenis_kelamin')"
                        />
                        <div class="d-flex align-items-center">
                          <span style="color: #263238; font-size: 12px">{{
                            value
                          }}</span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div
                    class="form-error"
                    v-if="formError && formError.jenis_kelamin"
                  >
                    {{ formError.jenis_kelamin }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Tanggal Lahir :</label>
                  <Datepicker
                    :autoApply="true"
                    v-model="formData.tanggal_lahir"
                    placeholder="Tanggal Lahir"
                    :format="format"
                    :enableTimePicker="false"
                    :disabled="!isEditable"
                    :maxDate="new Date()"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    :class="{
                      'is-invalid': formError && formError.tanggal_lahir,
                    }"
                    @update:modelValue="changeDate($event, 'tanggal_lahir')"
                  ></Datepicker>
                  <div
                    class="form-error"
                    v-if="formError && formError.tanggal_lahir"
                  >
                    {{ formError.tanggal_lahir }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Rekening :</label>

                  <div class="d-flex flex-row">
                    <div>
                      <Select2
                        placeholder="Pilih Bank"
                        class="align-self-center bank-options"
                        :options="optionBank"
                        style="width: 200px"
                        v-model="formData.nama_bank"
                        :disabled="!isEditable"
                        :class="{
                          'is-invalid': formError && formError.nama_bank,
                        }"
                        @change="myChangeEvent($event)"
                        @select="mySelectEvent($event)"
                      />
                    </div>
                    <input
                      type="text"
                      class="form-control align-self-center"
                      :class="{
                        'is-invalid': formError && formError.nomor_rekening,
                      }"
                      @keyup="formChange('nomor_rekening')"
                      v-model="formData.nomor_rekening"
                      placeholder="Masukkan  Nomor Rekening"
                      v-maska="'############################'"
                      :disabled="!isEditable"
                      style="
                        width: 100%;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                      "
                    />
                  </div>
                  <div
                    class="form-error"
                    v-if="
                      formError &&
                      (formError.nama_bank || formError.nomor_rekening)
                    "
                  >
                    {{
                      formError.nama_bank
                        ? formError.nama_bank
                        : formError.nomor_rekening
                    }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label> Tanggal Masuk :</label>
                  <Datepicker
                    :autoApply="true"
                    v-model="formData.tgl_masuk"
                    placeholder="Tanggal Mulai"
                    :disabled="!isEditable"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    :class="{
                      'is-invalid': formError && formError.tgl_masuk,
                    }"
                    @update:modelValue="changeDate($event, 'tgl_masuk')"
                  ></Datepicker>
                  <div
                    class="form-error"
                    v-if="formError && formError.tgl_masuk"
                  >
                    {{ formError.tgl_masuk }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-8">
                <div class="title">Detail Alamat</div>
                <div class="sub-title">Informasi alamat dari freelance</div>
              </div>
              <div class="col-4 d-flex align-items-center justify-content-end">
                <button
                  v-if="isEditable"
                  type="button"
                  @click="setAsResidenaceAddress"
                  style="font-size: 12px; padding: 5px 5px"
                  class="btn btn-orange"
                >
                  Jadikan Alamat Domisili
                </button>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="inputAddress2">Provinsi :</label>
                  <Select2
                    v-model="formData.id_provinsi"
                    :options="OptionsProvince"
                    placeholder="Provinsi"
                    id="provinsi"
                    :class="{
                      'is-invalid': formError && formError.id_provinsi,
                    }"
                    @change="locationEvent($event, 'id_provinsi')"
                    @select="locationEvent($event, 'id_provinsi')"
                    :disabled="!isEditable"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_provinsi"
                  >
                    {{ formError.id_provinsi }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputAddress2">Kabupaten :</label>
                  <Select2
                    v-model="formData.id_kabupaten"
                    :options="OptionsRegion"
                    placeholder="Kabupaten"
                    id="kabupaten"
                    :settings="{
                      settingOption: value,
                      settingOption: value,
                    }"
                    :class="{
                      'is-invalid': formError && formError.id_kabupaten,
                    }"
                    @change="locationEvent($event, 'id_kabupaten')"
                    @select="locationEvent($event, 'id_kabupaten')"
                    :disabled="!isEditable"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_kabupaten"
                  >
                    {{ formError.id_kabupaten }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputAddress2">Kecamatan :</label>
                  <Select2
                    v-model="formData.id_kecamatan"
                    :options="OptionsDistrict"
                    placeholder="Kecamatan"
                    id="kecamatan"
                    :settings="{
                      settingOption: value,
                      settingOption: value,
                    }"
                    :class="{
                      'is-invalid': formError && formError.id_kecamatan,
                    }"
                    @change="locationEvent($event, 'id_kecamatan')"
                    @select="locationEvent($event, 'id_kecamatan')"
                    :disabled="!isEditable"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_kecamatan"
                  >
                    {{ formError.id_kecamatan }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Alamat :</label>
                  <textarea
                    type="text"
                    v-model="formData.alamat"
                    style="height: unset !important"
                    rows="6"
                    class="form-control"
                    @keyup="formChange('alamat')"
                    :class="{ 'is-invalid': formError && formError.alamat }"
                    placeholder="Masukkan Alamat"
                    :disabled="!isEditable"
                  ></textarea>
                  <div class="form-error" v-if="formError && formError.alamat">
                    {{ formError.alamat }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <div class="title">Detail Alamat Domisili</div>
                <div class="sub-title">
                  Informasi alamat Domisili dari freelance
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="inputAddress2">Provinsi :</label>
                  <Select2
                    v-model="formData.domisili_provinsi"
                    :options="OptionsProvince"
                    placeholder="Provinsi"
                    id="provinsiResidence"
                    :class="{
                      'is-invalid': formError && formError.domisili_provinsi,
                    }"
                    @change="locationEvent($event, 'domisili_provinsi')"
                    @select="locationEvent($event, 'domisili_provinsi')"
                    :disabled="!isEditable"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.domisili_provinsi"
                  >
                    {{ formError.domisili_provinsi }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputAddress2">Kabupaten :</label>
                  <Select2
                    v-model="formData.domisili_kabupaten"
                    :options="OptionsRegionResidence"
                    placeholder="Kabupaten"
                    id="kabupatenResidence"
                    :settings="{
                      settingOption: value,
                      settingOption: value,
                    }"
                    :class="{
                      'is-invalid': formError && formError.domisili_kabupaten,
                    }"
                    @change="locationEvent($event, 'domisili_kabupaten')"
                    @select="locationEvent($event, 'domisili_kabupaten')"
                    :disabled="!isEditable"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.domisili_kabupaten"
                  >
                    {{ formError.domisili_kabupaten }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputAddress2">Kecamatan :</label>
                  <Select2
                    v-model="formData.domisili_kecamatan"
                    :options="OptionsDistrictResidence"
                    placeholder="Kecamatan"
                    id="kecamatanResidence"
                    :settings="{
                      settingOption: value,
                      settingOption: value,
                    }"
                    :class="{
                      'is-invalid': formError && formError.domisili_kecamatan,
                    }"
                    @change="locationEvent($event, 'domisili_kecamatan')"
                    @select="locationEvent($event, 'domisili_kecamatan')"
                    :disabled="!isEditable"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.domisili_kecamatan"
                  >
                    {{ formError.domisili_kecamatan }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Alamat Domisili :</label>
                  <textarea
                    type="text"
                    v-model="formData.domisili_alamat"
                    style="height: unset !important"
                    rows="6"
                    class="form-control"
                    @keyup="formChange('domisili_alamat')"
                    :class="{
                      'is-invalid': formError && formError.domisili_alamat,
                    }"
                    placeholder="Masukkan Alamat Domisili"
                    :disabled="!isEditable"
                  ></textarea>
                  <div
                    class="form-error"
                    v-if="formError && formError.domisili_alamat"
                  >
                    {{ formError.domisili_alamat }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <div class="title">Kontak Darurat</div>
                <div class="sub-title">
                  Informasi kontak darurat dari freelance
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Nama Kerabat :</label>
                  <input
                    type="text"
                    v-model="formData.nama_kerabat"
                    class="form-control align-self-center"
                    @keyup="formChange('nama_kerabat')"
                    :disabled="!isEditable"
                    :class="{
                      'is-invalid': formError && formError.nama_kerabat,
                    }"
                    placeholder="Masukkan Nama Kerabat"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.nama_kerabat"
                  >
                    {{ formError.nama_kerabat }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label> Nomor Telepon Kerabat :</label>
                  <input
                    type="text"
                    v-model="formData.no_hp_kerabat"
                    class="form-control align-self-center"
                    @keyup="formChange('no_hp_kerabat')"
                    :class="{
                      'is-invalid': formError && formError.no_hp_kerabat,
                    }"
                    placeholder="Masukkan Nomor Telepon Kerabat"
                    :disabled="!isEditable"
                    v-maska="'0###############'"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.no_hp_kerabat"
                  >
                    {{ formError.no_hp_kerabat }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>
  
  <script>
import { ref } from "vue";
import Datepicker from "vue3-date-time-picker";
import Select2 from "vue3-select2-component";
import NavbarDashboard from "../../../components/Navbar.vue";
import SidebarDashboard from "../../../components/Sidebar.vue";

import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-dt/js/dataTables.dataTables";

import $ from "jquery";
import { maska } from "maska";
import moment from "moment";
import "moment/locale/id";
import { get_CompanyAccessList } from "../../../actions/company/access";
import { get_ListPosition } from "../../../actions/company/posistion";
import {
get_KaryawanDetail,
get_KaryawanList,
get_KaryawanMasterStatus,
post_FreelanceSave,
} from "../../../actions/karyawan";
import {
get_ListBank,
get_ListDivisi,
get_ListKabupaten,
get_ListKecamatan,
get_ListProvinsi,
} from "../../../actions/master";
import { ROLE } from "../../../constans/Url";
import { checkRules, cksClient, showAlert } from "../../../helper";
// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  directives: {
    maska,
  },

  data() {
    return {
      dateOut: null,
      optionBank: [],
      OptionsGender: ["Laki - Laki", "Perempuan"],
      OptionsDistrict: [],
      OptionsRegion: [],
      OptionsProvince: [],
      OptionsDistrictResidence: [],
      OptionsRegionResidence: [],
      //FORMDATA START
      id_company: cksClient().get("_account").id_company,
      isPenggajian: false,
      showPassword: false,
      labelPage: this.$route.params.id ? "Detail" : "Tambah",
      isEditable: this.$route.params.id ? false : true,
      formData: {
        id_karyawan: this.$route.params.id ? this.$route.params.id : "",
        id_company: cksClient().get("_account").id_company,
        nama: "",
        email: "",
        no_hp: "",
        jenis_kelamin: "Laki - Laki",
        tanggal_lahir: "",
        no_ktp: "",
        nama_bank: "",
        nomor_rekening: "",
        alamat: "",
        id_provinsi: "",
        id_kecamatan: "",
        id_kabupaten: "",
        domisili_alamat: "",
        domisili_kecamatan: "",
        domisili_kabupaten: "",
        domisili_provinsi: "",
        tgl_masuk: "",
        no_hp_kerabat: "",
        nama_kerabat: "",
      },
      formError: [],
      rules: {
        nama: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
        no_hp: {
          required: true,
        },
        jenis_kelamin: {
          required: true,
        },
        tanggal_lahir: {
          required: true,
        },
        no_ktp: {
          required: true,
        },
        nama_bank: {
          required: true,
        },
        nomor_rekening: {
          required: true,
        },
        alamat: {
          required: true,
        },
        id_provinsi: {
          required: true,
        },
        id_kecamatan: {
          required: true,
        },
        id_kabupaten: {
          required: true,
        },
        domisili_alamat: {
          required: true,
        },
        domisili_kecamatan: {
          required: true,
        },
        domisili_kabupaten: {
          required: true,
        },
        domisili_provinsi: {
          required: true,
        },
        tgl_masuk: {
          required: true,
        },
        nama_kerabat: {
          required: true,
        },
        no_hp_kerabat: {
          required: true,
        },
      },
      isSubmit: false,
      isDelete: false,
      //FORMDATA END
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },
  created() {
    this.getKaryawan();
    this.getProvinsi();
    this.getBank();
    if (this.formData.id_karyawan) {
      this.getData();
    }
  },

  mounted() {
    this.formData.hak_akses = false;
    this.formData.hak_akses_raw = ROLE;
    $(".tab-pane.fade").removeClass("show active");
    setTimeout(() => {
      $("#pills-home-tab").tab("show");
      $("#pills-home").addClass("show active");
    }, 600);
  },

  methods: {
    editable() {
      this.isEditable = true;
    },
    getData() {
      get_KaryawanDetail(this.formData.id_karyawan, (res) => {
        var { data } = res;
        delete data.created_at;
        delete data.password;
        delete data.updated_at;
        delete data.expired_password_reset;
        this.getKabupaten(data.id_provinsi);
        this.getKecamatan(data.id_kabupaten);
        this.getKabupaten(data.domisili_provinsi, "domisili");
        this.getKecamatan(data.domisili_kabupaten, "domisili");
        this.formData = data;
      });
    },
    setAsResidenaceAddress() {
      this.OptionsDistrictResidence = [...this.OptionsDistrict];
      this.OptionsRegionResidence = [...this.OptionsRegion];
      this.formData.domisili_alamat = this.formData.alamat;
      this.formData.domisili_kecamatan = this.formData.id_kecamatan;
      this.formData.domisili_kabupaten = this.formData.id_kabupaten;
      this.formData.domisili_provinsi = this.formData.id_provinsi;
      this.formChange("domisili_alamat");
    },

    setAccess($event) {
      if ($event.tipe == "access") {
        this.formData.hak_akses = $event.value;
      } else if ($event.tipe == "divisi") {
        this.formData.akses_departement = $event.value;
      }
    },
    setRole($event) {
      if ($event.tipe == "access") {
        this.formData.hak_akses_raw = $event.value;
      } else if ($event.tipe == "divisi") {
        this.formData.akses_departement_raw = $event.value;
      }
    },
    //MASTER
    getAccess() {
      get_CompanyAccessList(
        {
          id_company: this.id_company,
        },
        (res) => {
          this.OptionAccess = res.list;
        }
      );
    },
    getKaryawan() {
      get_KaryawanList(
        {
          id_company: cksClient().get("_account").id_company,
          search: "aktif",
          id_divisi: "",
        },
        async (res) => {
          var { list } = res;
          this.optionEmployee = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              if (element.id != this.formData.id_karyawan) {
                this.optionEmployee.push({
                  id: element.id,
                  text: element.nama,
                });
              }
            }
          }
        }
      );
    },
    getBank() {
      get_ListBank((res) => {
        this.optionBank = res.list;
      });
    },
    async getProvinsi() {
      await get_ListProvinsi(
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsProvince = arr;
        },
        () => {
          this.OptionsProvince = [];
        }
      );
    },
    async getKabupaten(id, type = "") {
      await get_ListKabupaten(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          if (type != "") {
            this.OptionsRegionResidence = arr;
          } else {
            this.OptionsRegion = arr;
          }
        },
        () => {
          if (type != "") {
            this.OptionsRegionResidence = [];
          } else {
            this.OptionsRegion = [];
          }
        }
      );
    },

    async getKecamatan(id, type = "") {
      await get_ListKecamatan(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          if (type != "") {
            this.OptionsDistrictResidence = arr;
          } else {
            this.OptionsDistrict = arr;
          }
        },
        () => {
          if (type != "") {
            this.OptionsDistrictResidence = [];
          } else {
            this.OptionsDistrict = [];
          }
        }
      );
    },
    locationEvent(val, key) {
      this.formChange(key);
      if (key === "id_provinsi") {
        this.OptionsDistrict = [];
        this.OptionsRegion = [];
        this.getKabupaten(val.id);
      } else if (key === "id_kabupaten") {
        this.OptionsDistrict = [];
        this.getKecamatan(val.id);
      } else if (key == "domisili_provinsi") {
        this.OptionsDistrictResidence = [];
        this.OptionsRegionResidence = [];
        this.getKabupaten(val.id, "domisili");
      } else if (key == "domisili_kabupaten") {
        this.OptionsDistrictResidence = [];
        this.getKecamatan(val.id, "domisili");
      }
    },
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.OptionsDepartement = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.OptionsDepartement.push(element);
          }
        }
      });
    },
    getStatus() {
      get_KaryawanMasterStatus(this.id_company, (res) => {
        const { list } = res;
        this.OptionsStatusEmployee = list;
      });
    },
    getJabatan() {
      get_ListPosition({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.OptionsPositions = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.OptionsPositions.push({
              id: element.id,
              text: element.nama,
            });
          }
        }
      });
    },
    changeDate(data, key) {
      this.formData[key] = moment(data).format("YYYY-MM-DD");
      this.formChange(key);
    },
    //END MASTER
    postData(status = "aktif") {
      var data = { ...this.formData };
      data.status = status;
      if (status == "delete") {
        this.isDelete = true;
      } else {
        this.isSubmit = true;
      }
      post_FreelanceSave(
        data,
        (res) => {
          var msg = this.isDelete
            ? "Data freelance berhasil dihapus"
            : res.response_code
            ? "Data freelance berhasil dicatat"
            : "Data freelance berhasil diperbarui";
          if (status == "delete") {
            this.isDelete = false;
          } else {
            this.isSubmit = false;
          }
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({
                name: "DataFreelance",
              });
            },
            onDismiss: () => {
              this.$router.push({
                name: "DataFreelance",
              });
            },
          });
        },
        () => {
          if (status == "delete") {
            this.isDelete = false;
          } else {
            this.isSubmit = false;
          }
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali nanti",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData("aktif");
          },
        });
      }
    },

    async onDelete() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, akan menghapus data ini?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData("delete");
          },
        });
      }
    },

    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({
        id,
        text,
      });
    },
  },
};
</script>
  
  <style scoped src="../../../assets/css/style.css"></style>
  
  <style scoped src="../../../assets/css/custom.css"></style>
  
  <style scoped>
.avatar img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  object-fit: cover;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

.form-edit {
  width: 100%;
}

.col-left {
  padding: 30px 26px;
  border: 1px solid #eceff1;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: none;
}

.col-right {
  border: 1px solid #eceff1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.title-detail {
  padding: 30px;
}

.text {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  width: 488px;
  max-width: 236px;
}

.value {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.info-detail {
  padding: 30px;
}

hr {
  margin: 0px;
  padding: 0px;
}

.col-left hr {
  margin: 20px 0px;
}

.row {
  /* border: 1px solid #ECEFF1; */
  border-radius: 10px;
}

.name-employee {
  font-size: 18px;
  font-weight: 600;
  margin-top: 1rem;
}

.position {
  color: #c6c6c6;
  font-size: 14px;
  margin-top: 0.5rem;
}

.menu .nav-link {
  color: #000;
}

.menu .nav-link.active {
  background: #68caf8;
  color: #fff;
  border-radius: 5px;
  padding: 10px 30px;
}

.menu {
  border: none;
  background: none;
}

.list-menu {
  margin-top: 30px;
}

ul li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

ul {
  margin: 0px;
  padding: 0px;
}

.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card .nav-pills {
  line-height: 30px;
}

.card .nav .nav-item {
  margin-right: 30px;
}

.card {
  padding: 63px 50px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  color: #000;
}

.nav-pills .nav-link {
  color: #898989;
}

.nav-pills .nav-link svg {
  margin-right: 10px;
}

.bank-options .select2-container--default .select2-selection--single {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: 0px !important;
}
.btn-resign {
  background: transparent !important;
  border-color: #ff3d3d !important;
  color: #ff3d3d !important;
}
.btn-resign:hover {
  background: #ff3d3d !important;
  color: #fff !important;
}
</style>
  